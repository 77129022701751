* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }

  h1 {
    color: #ffffff;
  }
  
  .home,
  .services,
  .products,
  .sign-up {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    background-color: #000;
  }
  
  .services {
    /* background-image: url('/public/images/img-2.jpg'); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
    background-color: #000;
  }
  
  .products {
    /* background-image: url('/public/images/img-1.jpg'); */
    background-position: center;
    background-size: fill;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
    background-color: #000;
  }
  
  .sign-up {
    /* background-image: url('/public/images/img-8.jpg'); */
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
    background-color: #000;
  }

  .ph-text {
    color:#000;
    text-align: center;
    padding:20px;
  }

  .f-25 {
    font-size: 25px;
  }

  @media screen and (min-width:900px) {
    .f-25 {
      margin:0 20% !important;
    }
  }

  .center {
    text-align:center;
  }

  .wrap {
    overflow-wrap: word-break;
  }

  .pad-30 {
    padding:10px;
  }