@media screen and (min-width: 800px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: 120px;
  }
}

/* video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
} */

.hero-container {
  background-image: url('../../public/images/ac3.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2); */
  position: absolute;
  top: 0;
  bottom: 0;
}

.hero-container > h1 {
  color: #ffffff;;
  font-size: 70px;
  /* margin-top: -100px; */
  text-align: center;
  font-family: 'baskerville';
}

.hero-container > p {
  margin-top: 8px;
  color: #ffffff;;
  font-size: 32px;
  font-family: 'Baskerville', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and ( min-width:600) and ( max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -130px;
  }



  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}

.circle-me {
  width: 50%;
  border-radius: 50% 50%;
  border: 5px solid #ffffff;
}

h1 {
  color: #ffffff;
}